import React, { useEffect, useContext } from 'react';
import { Link } from 'gatsby';
import { Layout } from '../../../components/Layout/Layout';
import { UserContext } from '../../../components/AppContext';
import StyledWrapper from './styles.js';
import { Subscription } from '../../../components/Subscription/Subscription';

const SubscriptionPage = () => {
  const setActiveTab = e => {
    const a = document.querySelectorAll('.plan');
    a.forEach(item => {
      e.target.id == item.id ? item.classList.add('active') : item.classList.remove('active');
    });
  };
  const { user, subscriptionData } = useContext(UserContext);
  return (
    <Layout>
      <StyledWrapper>
        <div className="box">
          <h2>Windframe</h2>
          <ul className="nav nav-tabs">
            <li className="nav-item">
              <a
                className="nav-link plan active"
                id="subscriptions"
                onClick={setActiveTab}
                data-toggle="tab"
                href="#subscriptions"
              >
                Subscriptions
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link plan"
                id="saved"
                onClick={setActiveTab}
                data-toggle="tab"
                href="#saved"
              >
                Saved Pages
              </a>
            </li>
          </ul>
          <div className="tab-content">
            {subscriptionData && Object.keys(subscriptionData).length === 0 ? (
              <div className="row plan active" id="subscriptions">
                <h5 className="mx-auto">You don't have an active subscription.</h5>
                <div className="button">
                  <Link href="/windframe/pricing">
                    <button className="btn1 btn__inverse">Subscribe now!</button>
                  </Link>
                </div>
              </div>
            ) : (
              <Subscription />
            )}
            <div className="row plan" id="saved">
              <h5 className="mx-auto">Coming Soon!</h5>
            </div>
          </div>
        </div>
      </StyledWrapper>
    </Layout>
  );
};

export default SubscriptionPage;
