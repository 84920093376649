import React, { useContext } from 'react';
import { Styles } from './styles';
import { UserContext } from '../AppContext';

export const Subscription = () => {
  const { subscriptionData } = useContext(UserContext);

  if (subscriptionData !== null) {
    return (
      <Styles>
        {subscriptionData && subscriptionData.error ? (
          <div> Your do not have an active subscription status </div>
        ) : (
          <>You are currently subscribed to Windframe Pro</>
        )}
      </Styles>
    );
  }
  return <Styles>Loading......</Styles>;
};
